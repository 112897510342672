import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 pt-4 bg-white text-gray-800 h-screen flex items-center justify-center">
      <div>
        <h1 className="text-4xl font-bold text-center">Fehler: 404</h1>
        <p className="text-3xl mt-3 mb-2 text-center">
          Leider konnte diese Seite nicht gefunden werden.
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
